export function toLowerCamelCase(string: string) {
  return string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');
}

export function uppercaseFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
