import GradientMask from '@atoms/GradientMask';
import RichText from '@atoms/RichText/RichText';
import Time from '@atoms/Time/Time';
import { DEFAULT_FOCUS_POINT, FOCUS_POINT } from '@constants/constants';
import CtaButton from '@molecules/CtaButton/CtaButton';
import { ContentHero as ContentHeroType } from '@type-declarations/hero';
import useContentHeroAnimation from '@utils/animations/useContentHeroAnimation';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { useRef } from 'react';

import styles from './ContentHero.module.scss';

interface Props extends Omit<ContentHeroType, 'title'> {
  shortTitle: string;
  longTitle?: string;
  date?: string;
  children?: React.ReactNode;
}

function Title({
  shortTitle,
  longTitle,
}: Pick<Props, 'shortTitle' | 'longTitle'>) {
  if (longTitle) {
    return (
      <>
        <h1 className="u-visually-hidden">{shortTitle}</h1>
        <div className={clsx('u-typography-heading-1 u-mb-0', styles.title)}>
          {longTitle}
        </div>
      </>
    );
  }

  return <h1 className="u-mb-0">{shortTitle}</h1>;
}

function ContentHero({
  shortTitle,
  longTitle,
  text,
  image,
  focusPoint,
  cta,
  secondCta,
  date,
  children,
}: Props) {
  const mediaRef = useRef<HTMLDivElement>(null!);

  useContentHeroAnimation(mediaRef, styles.mediaAnimation);

  return (
    <div className={styles.hero}>
      <div className={clsx('u-container', styles.columns)}>
        <div
          className={clsx(styles.firstColumn, (text || date) && styles.narrow)}
        >
          <Title shortTitle={shortTitle} longTitle={longTitle} />

          {(cta || secondCta) && (
            <div className={styles.ctaWrapper}>
              {cta && <CtaButton cta={cta} />}
              {secondCta && <CtaButton cta={secondCta} variant="tertiary" />}
            </div>
          )}

          {children && <div className={styles.children}>{children}</div>}
        </div>

        {(text || date) && (
          <div className={styles.secondColumn}>
            {text && <RichText text={text} className={styles.text} />}
            {date && <Time date={date} className={styles.date} />}
          </div>
        )}
      </div>

      {image && (
        <div className={styles.mediaTransition} ref={mediaRef}>
          <div className={styles.mediaContainer}>
            <Image
              src={image.url}
              placeholder="blur"
              blurDataURL={image.placeholder}
              alt={image.description || ''}
              layout="fill"
              objectFit="cover"
              objectPosition={FOCUS_POINT[focusPoint || DEFAULT_FOCUS_POINT]}
              priority
              className={styles.media}
              quality={100}
            />

            <GradientMask />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentHero;
