import { Video as VideoType } from '@type-declarations/media';
import { useEffect, useState } from 'react';

interface Props {
  video: VideoType;
  autoplay?: boolean;
  loop?: boolean;
  inView: boolean;
  className?: string;
}

export default function YoutubeVideoPlayer({
  video: { youtubeId, alt = '' },
  autoplay,
  loop,
  inView,
  className,
}: Props) {
  const [videoHasStarted, setVideoHasStarted] = useState(false);

  useEffect(() => {
    if (inView && autoplay && !videoHasStarted) {
      setVideoHasStarted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  if (!youtubeId) return null;

  return (
    <iframe
      className={className}
      src={`https://www.youtube-nocookie.com/embed/${youtubeId}?${
        autoplay && videoHasStarted ? 'autoplay=1&mute=1' : ''
      }${autoplay && loop ? '&' : ''}${loop ? `loop=1&playlist=${youtubeId}` : ''}`}
      allow="autoplay"
      allowFullScreen
      title={alt}
    />
  );
}
