import { type MuxPlayerRefAttributes } from '@mux/mux-player-react';
import { uppercaseFirst } from '@utils/formatString';
import { useState } from 'react';

const events = {
  start: 'muxVideoStart',
  progress: 'muxVideoProgress',
  end: 'muxVideoComplete',
  pause: 'muxVideoPause',
  play: 'muxVideoPlay',
} as const;

const push = (
  event: (typeof events)[keyof typeof events],
  playbackId?: string,
  data?: { [key: string]: unknown },
) => {
  if (!playbackId) return;

  prepr('event', uppercaseFirst(event), {
    videoPlaybackId: playbackId,
    ...data,
  });

  if (!window.dataLayer) return;
  window.dataLayer.push({ event, videoPlaybackId: playbackId, ...data });
};

const milestonesInPercentages = [10, 25, 50, 75, 90] as const;

const defaultMilestoneState = milestonesInPercentages.reduce(
  (acc, milestone) => ({ ...acc, [milestone]: false }),
  {},
) as { [key in (typeof milestonesInPercentages)[number]]: boolean };

// Does not keep track of milestones when seeking backwards or forewards.
export function useMuxVideoPlayerEvents({
  player,
}: {
  player: MuxPlayerRefAttributes | null;
}) {
  const [videoHasStarted, setVideoHasStarted] = useState(false);
  const [progressMilestones, setProgressMilestones] = useState(
    defaultMilestoneState,
  );

  const resetState = () => {
    setVideoHasStarted(false);
    setProgressMilestones(defaultMilestoneState);
  };

  const onPlay = () => {
    if (!player) return;

    if (!videoHasStarted) {
      push(events.start, player.playbackId);
      setVideoHasStarted(true);
      return;
    }

    push(events.play, player.playbackId);
  };

  const onPause = () => {
    if (!player) return;
    push(events.pause, player.playbackId);
  };

  const onTimeUpdate = () => {
    if (!player) return;

    const progress = (player.currentTime / player.duration) * 100;

    milestonesInPercentages.forEach(milestone => {
      if (progress >= milestone && !progressMilestones[milestone]) {
        push(events.progress, player.playbackId, {
          videoProgress: milestone,
        });
        setProgressMilestones(prev => ({ ...prev, [milestone]: true }));
      }
    });
  };

  const onEnded = () => {
    if (!player) return;
    push(events.end, player.playbackId);
    resetState();
  };

  return { onPlay, onPause, onTimeUpdate, onEnded };
}
