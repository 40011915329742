import Button from '@atoms/Button/Button';
import MuxVideoPlayer from '@atoms/Video/MuxVideoPlayer';
import YoutubeVideoPlayer from '@atoms/Video/YoutubeVideoPlayer';
import useTranslations from '@hooks/useTranslations';
import { Video as VideoType } from '@type-declarations/media';
import clsx from 'clsx';
import Image from 'next/legacy/image';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './Video.module.scss';

interface Props {
  video: VideoType;
  autoplay?: boolean;
  loop?: boolean;
  className?: string | false;
}

function VideoPlayer({
  video,
  autoPlay,
  loop,
  inView,
  className,
}: {
  video: VideoType;
  autoPlay?: boolean;
  loop?: boolean;
  inView: boolean;
  className?: string;
}) {
  if (video.youtubeId) {
    return (
      <YoutubeVideoPlayer
        video={video}
        autoplay={autoPlay}
        loop={loop}
        inView={inView}
        className={className}
      />
    );
  }

  return (
    <MuxVideoPlayer
      video={video}
      autoplay={autoPlay}
      loop={loop}
      inView={inView}
      className={className}
    />
  );
}

function Video({ video, autoplay = false, loop = false, className }: Props) {
  const { ref: containerRef, inView } = useInView();
  const [hasConsent, setHasConsent] = useState(false);
  const t = useTranslations();

  useEffect(() => {
    const checkConsent = () => {
      if (!window.Cookiebot) {
        setHasConsent(true);
        return;
      }
      setHasConsent(window.Cookiebot.consent.marketing);
    };

    // Initial check
    checkConsent();

    window.addEventListener('CookiebotOnLoad', checkConsent);
    window.addEventListener('CookiebotOnAccept', checkConsent);
    window.addEventListener('CookiebotOnDecline', checkConsent);

    return () => {
      window.removeEventListener('CookiebotOnLoad', checkConsent);
      window.removeEventListener('CookiebotOnAccept', checkConsent);
      window.removeEventListener('CookiebotOnDecline', checkConsent);
    };
  }, []);

  return (
    <div ref={containerRef} className={clsx(styles.mediaContainer, className)}>
      {!hasConsent ? (
        <>
          {video.thumbnail?.url && (
            <div className={styles.thumbnailWrapper}>
              <Image
                src={video.thumbnail.url}
                alt={video.alt || ''}
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={video.thumbnail.placeholder}
                className={styles.media}
              />
            </div>
          )}
          <div className={styles.consentBox}>
            <div className={styles.consentContent}>
              <p className={styles.consentText}>{t.videoConsentText}</p>
              <Button
                label={t.videoManageCookies}
                onClick={() => window.Cookiebot?.show()}
                variant="primary"
                size="sm"
              />
            </div>
          </div>
        </>
      ) : (
        <VideoPlayer
          video={video}
          autoPlay={autoplay}
          loop={loop}
          inView={inView}
          className={styles.media}
        />
      )}
    </div>
  );
}

export default Video;
