import Button from '@atoms/Button/Button';
import styles from '@atoms/Video/MuxVideoPlayer.module.scss';
import useMedia from '@hooks/useMedia';
import { useMuxVideoPlayerEvents } from '@hooks/useMuxVideoPlayerEvents';
import MuxPlayer, { type MuxPlayerRefAttributes } from '@mux/mux-player-react';
import { Video as VideoType } from '@type-declarations/media';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';

interface Props {
  video: VideoType;
  autoplay?: boolean;
  loop?: boolean;
  inView: boolean;
  className?: string;
}

export default function MuxVideoPlayer({
  video: { embeddedVideo, alt = '', thumbnail },
  autoplay,
  loop,
  inView,
  className,
}: Props) {
  const videoRef = useRef<MuxPlayerRefAttributes>(null);
  const { isMediaPlaying, play, pause } = useMedia({ reference: videoRef });
  const { onPlay, onPause, onTimeUpdate, onEnded } = useMuxVideoPlayerEvents({
    player: videoRef.current,
  });

  useEffect(() => {
    if (!autoplay) return;

    if (inView) {
      play();
    } else {
      pause();
    }
  }, [autoplay, play, pause, inView]);

  if (!embeddedVideo) return null;

  return (
    <>
      <MuxPlayer
        playbackId={embeddedVideo.playbackId}
        ref={videoRef}
        className={clsx(
          className,
          (!isMediaPlaying || (autoplay && loop)) && styles.withoutControls,
        )}
        title={alt}
        muted={autoplay}
        loop={loop}
        poster={thumbnail?.url}
        onPlay={onPlay}
        onPause={onPause}
        onTimeUpdate={onTimeUpdate}
        onEnded={onEnded}
        autoPlay={autoplay}
      />
      {!isMediaPlaying && !autoplay && (
        <div className={styles.buttonContainer}>
          <Button
            icon="play"
            className={styles.button}
            onClick={play}
            label="Play video"
            hideLabel
            variant="primary"
          />
        </div>
      )}
    </>
  );
}
