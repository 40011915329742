import useStore from '@hooks/useStore';
import GradientMaskDefault from '@img/gradient-mask.png';
import GradientMaskBridg from '@img/gradient-mask-bridg.png';
import GradientMaskNimbl from '@img/gradient-mask-nimbl.png';
import { isBridgDomain, isNimblDomain } from '@utils/getDomain';
import Image from 'next/legacy/image';

const getGradientMask = (host: string) => {
  switch (true) {
    case isNimblDomain(host):
      return GradientMaskNimbl;
    case isBridgDomain(host):
      return GradientMaskBridg;
    default:
      return GradientMaskDefault;
  }
};

export default function GradientMask() {
  const {
    store: { host },
  } = useStore();

  return (
    <Image src={getGradientMask(host)} layout="fill" objectFit="cover" alt="" />
  );
}
